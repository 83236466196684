function pageInitialState() {
    return {
        title: null
    };
}

export default {
    state: () => pageInitialState(),
    mutations: {
        setTitle(state, title) {
            state.title = document.title = title;
        },
        resetPageState(state) {
            Object.assign(state, pageInitialState());
        }
    }
};
